<template>
  <div>
    <a-modal
      :visible="visible"
      :title="isEdit ? $t('添加别名') : $t(`新建`) + $t(`地址`)"
      @cancel="cancelModel"
      @ok="handleOk"
      width="800px"
    >
      <a-form-model
        :model="formData"
        ref="formRef"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 8 }"
        autocomplete="off"
        :layout="'horizontal'"
      >
        <p>{{ $t(`基本信息`) }}</p>
        <a-form-model-item
          :label="$t(`地址`) + $t(`名称`)"
          prop="region_name"
          :rules="[{ required: true, message: 'Please input your region name!' }]"
        >
          <a-input v-model="formData.region_name" :disabled="isEdit" style="width: 300px" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t(`所属级别`)"
          prop="region_type"
          required
          :rules="[{ required: true, message: 'Please select your region type!' }]"
        >
          <a-select
            ref="select"
            v-model="formData.region_type"
            style="width: 300px"
            :disabled="isEdit"
          >
            <a-select-option :value="0">{{ $t(`国家`) }}</a-select-option>
            <a-select-option :value="1">{{ $t(`省`) }}</a-select-option>
            <a-select-option :value="2">{{ $t(`市`) }}</a-select-option>
            <a-select-option :value="3">{{ $t(`区`) }}</a-select-option>
            <a-select-option :value="4">{{ $t(`邮编`) }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="formData.region_type && formData.region_type != '0'"
          :label="$t(`上级地址`)"
          required
        >
          <a-row v-if="isEdit">
            <a-col :span="7">
              {{ formData.parent_name }}
            </a-col>
          </a-row>
          <a-row :gutter="16" v-else>
            <a-col :span="5" v-if="formData.region_type > 0">
              <!-- 国家 -->
              <label>{{ $t(`国家`) }}</label>
              <template>
                <a-form-model-item
                  prop="countryId"
                  required
                  :rules="[{ required: true, message: 'Please select your country!' }]"
                >
                  <SelectRegion
                    region-type="0"
                    parent-id="0"
                    @selected="selectedCountry"
                    required
                    :default-region-id="formData.countryId"
                  />
                </a-form-model-item>
              </template>
            </a-col>
            <a-col :span="5" v-if="formData.region_type > 1">
              <!-- 省-->
              <label>{{ $t(`省`) }}</label>
              <template>
                <a-form-model-item
                  prop="provinceId"
                  required
                  :rules="[{ required: true, message: 'Please select your province!' }]"
                >
                  <SelectRegion
                    region-type="1"
                    :parent-id="formData.countryId"
                    @selected="selectedProvince"
                    required
                    :default-region-id="formData.provinceId"
                  />
                </a-form-model-item>
              </template>
            </a-col>
            <a-col :span="5" v-if="formData.region_type > 2">
              <!-- 市-->
              <label>{{ $t(`市`) }}</label>
              <a-form-model-item
                prop="cityId"
                required
                :rules="[{ required: true, message: 'Please select your city!' }]"
              >
                <SelectRegion
                  region-type="2"
                  :parent-id="formData.provinceId"
                  @selected="selectedCity"
                  required
                  :default-region-id="formData.cityId"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="formData.region_type > 3">
              <!-- 区-->
              <label>{{ $t(`区`) }}</label>
              <a-form-model-item
                prop="countyId"
                required
                :rules="[{ required: true, message: 'Please select your county!' }]"
              >
                <SelectRegion
                  region-type="3"
                  :parent-id="formData.cityId"
                  @selected="selectedCounty"
                  required
                  :default-region-id="formData.countyId"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>

        <template v-if="isEdit">
          <p class="mt10">{{ $t(`别名`) }}</p>
          <div
            v-for="(mapping, index) in formData.region_mapping_list"
            :key="mapping.id"
            style="display: flex; margin-bottom: 8px"
            align="baseline"
          >
            <a-row>
              <span style="margin-right: 10px">{{ index + 1 }}.</span>
              <a-col span="7">
                <a-form-model-item
                  :label="$t(`地址别名`)"
                  :prop="'region_mapping_list.' + index + '.region_name'"
                  :rules="{
                    required: true,
                    message: $t('请输入地址别名'),
                  }"
                >
                  <a-input
                    v-model="mapping.platform_region_name"
                    placeholder="Mapping Relationships"
                  />
                </a-form-model-item>
              </a-col>
              <a-col span="7" offset="1">
                <a-form-model-item
                  :label="$t(`平台`) + $t(`类型`)"
                  :prop="'region_mapping_list.' + index + '.platform_type'"
                  :rules="{
                    required: true,
                    message: $t('请选择平台类型'),
                  }"
                >
                  <a-select
                    ref="select"
                    v-model="mapping.platform_type"
                    style="width: 120px"
                    @change="mapping.platform = undefined"
                  >
                    <a-select-option :value="0">{{ $t(`电商平台`) }}</a-select-option>
                    <a-select-option :value="1">{{ $t(`快递`) }}</a-select-option>
                    <a-select-option :value="2">{{ $t(`仓库`) }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col span="7" offset="1">
                <a-form-model-item
                  label="所属平台"
                  :prop="'region_mapping_list.' + index + '.platform'"
                  :rules="{
                    required: true,
                    message: $t('请选择平台'),
                  }"
                >
                  <a-select ref="select" v-model="mapping.platform" style="width: 120px">
                    <a-select-option
                      :value="item"
                      v-for="item in platforms[mapping.platform_type]"
                      :key="item"
                    >
                      {{ platformMapping[item] || item }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col span="1" offset="1">
                <a-form-model-item label="">
                  <a-icon
                    v-if="!mapping.region_mapping_id"
                    class="dynamic-delete-button"
                    type="minus-circle-o"
                    @click="removeMapping(mapping)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <a-form-item>
            <a-button type="primary" @click="addMapping">
              {{ $t(`新增别名`) }}
            </a-button>
          </a-form-item>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import SelectRegion from '@component/selectRegion'
export default {
  name: '',
  props: ['isEdit', 'editDtata'],
  data() {
    return {
      visible: true,
      formData: {
        platform: 'OMS',
        regionId: '',
        region_name: '',
        region_type: '',
        countryId: '',
        provinceId: '',
        cityId: '',
        countyId: '',
        region_mapping_list: [],
      },
      countryId: undefined,
      provinceId: undefined,
      cityId: undefined,
    }
  },
  computed: {
    ...mapGetters(['platformMapping', 'country']),
    countryMap() {
      return this.$store.state.countryMap
    },
    parentId() {
      return this.countryMap[this.country.toUpperCase()]
    },
    platforms() {
      let platformsList = Array.from(
        new Set(this.$store.state.nowPlatformList.map((item) => item.code))
      )
      return {
        0: platformsList,
        1: ['Flash', 'JT'],
        2: ['Topmart', 'Mabang', 'CG-WMS'],
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.formData = JSON.parse(JSON.stringify(this.editDtata))
    }
  },
  methods: {
    handleOk() {
      this.formData.platform = 'OMS'
      this.$refs['formRef'].validate().then((valid) => {
        if (valid) {
          http({
            instance: this,
            url: api.saveRegionMappingList,
            type: 'post',
            data: this.formData,
            success: () => {
              if (this.isEdit) {
                this.$success(this.$t(`修改成功`))
              } else {
                this.$success(this.$t(`新建成功`))
              }
              this.cancelModel()
            },
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    removeMapping(mapping) {
      if (mapping.region_mapping_id) {
        this.$confirm({
          title: 'Are you sure you want to delete the mapping relationship?',
          content: '',
          onOk() {
            http({
              url: api.deleteRegionMappingList,
              type: 'post',
              data: {
                regionMappingId: mapping.region_mapping_id,
                platform: 'OMS',
              },
              success: () => {
                this.$success(this.$t('Deleted successfully'))
                this.cancelModel()
              },
            })
          },
          onCancel() {},
        })
      } else {
        let index = this.formData.region_mapping_list.indexOf(mapping)
        if (index !== -1) {
          this.formData.region_mapping_list.splice(index, 1)
        }
      }
    },
    addMapping() {
      this.formData.region_mapping_list.push({
        regionId: '',
        regionMappingId: '',
        region_name: this.formData.region_name,
        platform_region_name: '',
        platform: '',
        platform_type: '',
      })
    },
    selectedCountry(data) {
      this.formData.countryId = data.region_id
      this.formData.provinceId = undefined
      this.formData.cityId = undefined
      this.formData.countyId = undefined
    },
    selectedProvince(data) {
      this.formData.provinceId = data.region_id
      this.formData.cityId = undefined
      this.formData.countyId = undefined
    },
    selectedCity(data) {
      this.formData.cityId = data.region_id
      this.formData.countyId = undefined
    },
    selectedCounty(data) {
      this.formData.countyId = data.region_id
    },
    cancelModel() {
      this.$emit('cancel', false)
    },
  },
  components: {
    SelectRegion,
  },
  watch: {
    countryId() {
      this.provinceId = undefined
      this.cityId = undefined
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

::v-deep .ant-form-horizontal .ant-form-item-label {
  width: 80px;
}

::v-deep .ant-form-horizontal .ant-form-item-control-wrapper {
  flex-grow: 0;
  flex: 1;

  .ant-input {
    width: 120px;
  }
}
</style>
