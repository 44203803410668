<template>
  <div class="content-wrapper">
    <div class="content">
      <a-row class="mb10">
        <!-- 新建地址 -->
        <a-button type="primary" @click="newAddressBankList('')">
          <i class="iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t(`新建`) + $t(`地址`) }}
        </a-button>
      </a-row>
      <a-row class="mb10" :gutter="16">
        <a-col :span="7">
          <!-- 编号/名称 -->
          <a-input
            allowClear
            :placeholder="$t(`编号`) + '/' + $t(`名称`)"
            type="text"
            v-model="addressBankName"
          ></a-input>
        </a-col>
        <a-col :span="4">
          <!-- 国家 -->
          <SelectRegion
            ref="region1"
            region-type="0"
            parent-id="0"
            @selected="selectedCountry"
            :defaultRegionId="countryId"
            :placeholder="$t('国家')"
          />
        </a-col>
        <a-col :span="4">
          <!-- 省 -->
          <SelectRegion
            ref="region2"
            region-type="1"
            :parent-id="countryId"
            @selected="selectedProvince"
            :placeholder="$t('省')"
          />
        </a-col>
        <a-col :span="4">
          <!-- 市 -->
          <SelectRegion
            ref="region3"
            region-type="2"
            :parent-id="provinceId"
            @selected="selectedCity"
            :placeholder="$t('市')"
          />
        </a-col>
        <a-col :span="5">
          <a-button v-if="priv('f_gift_stategy_list')" type="primary" @click="getAddressBankList">
            <i class="iconfont icon-search" style="margin-right: 5px"></i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
      <div class="table-content">
        <div class="table-body">
          <a-table
            :loading="loading"
            :columns="columns"
            :dataSource="addressBankList"
            :pagination="false"
            :scroll="{ x: 500 }"
            :rowKey="
              (record, index) => {
                return index
              }
            "
          >
            <template slot="Level" slot-scope="text, record">
              {{ regionTypeFilter(record.region_type) }}
            </template>
            <template slot="MappingRelationships" slot-scope="text, record">
              <div v-for="(item, i) in record.region_mapping_list" :key="i">
                {{ `${item.platform_region_name} / ${item.platform}` }}
              </div>
            </template>
            <template slot="Action" slot-scope="text, record">
              <span class="text-main" style="cursor: pointer" @click="newAddressBankList(record)">
                {{ $t(`添加别名`) }}
              </span>
            </template>
          </a-table>
        </div>
        <div class="table-footer">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </div>
    <AddressBankModal v-if="showModal" :isEdit="isEdit" :editDtata="editDtata" @cancel="cancel" />
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import AddressBankModal from '@/component/addressBankModal'
import pagination from '@component/pagination'
import SelectRegion from '@component/selectRegion'
export default {
  name: 'AddressBankManagement',
  data() {
    return {
      addressBankName: '',
      addressBankList: [],
      showModal: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      isEdit: false,
      editDtata: {},
      countryId: undefined,
      provinceId: undefined,
      cityId: undefined,
      loading: false,
    }
  },
  mounted() {
    this.countryId = this.$store.state.countryMap[this.$store.getters.country]
    this.getAddressBankList()
  },
  methods: {
    reset() {
      this.addressBankName = ''
      this.countryId = undefined
      this.provinceId = undefined
      this.cityId = undefined
      this.$refs.region1.value = undefined
      this.$refs.region2.value = undefined
      this.$refs.region3.value = undefined
      this.getAddressBankList()
    },
    newAddressBankList(record) {
      this.showModal = true
      this.isEdit = false
      if (record) {
        this.isEdit = true
        this.editDtata = record
      }
    },
    async getAddressBankList() {
      this.loading = true
      await http({
        url: api.getRegionMappingList,
        type: 'post',
        data: {
          regionId: this.cityId || this.provinceId || this.countryId,
          platform: 'OMS',
          regionName: this.addressBankName,
          pageVO: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
          },
        },
        success: (res) => {
          this.addressBankList = res.result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    regionTypeFilter(val) {
      switch (val) {
        case 0:
          return this.$t(`国家`)
        case 1:
          return this.$t(`省`)
        case 2:
          return this.$t(`市`)
        case 3:
          return this.$t(`区`)
        case 4:
          return this.$t(`邮编`)
      }
    },
    cancel() {
      this.showModal = false
      this.getAddressBankList()
    },
    paginate(data) {
      this.pageNo = Number(data.pageNo)
      this.pageSize = data.length
      this.getAddressBankList()
    },
    selectedCountry(data) {
      this.countryId = data.region_id
      this.provinceId = undefined
      this.cityId = undefined
    },
    selectedProvince(data) {
      this.provinceId = data.region_id
      this.cityId = undefined
    },
    selectedCity(data) {
      this.cityId = data.region_id
    },
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t(`编号`),
          dataIndex: 'region_id',
          key: 'regionId',
          width: 100,
        },
        {
          title: this.$t(`名称`),
          scopedSlots: { customRender: 'Name' },
          dataIndex: 'region_name',
          key: 'regionName',
          width: 100,
        },
        {
          title: this.$t(`级`),
          scopedSlots: { customRender: 'Level' },
          key: 'Level',
          width: 100,
        },
        {
          title: this.$t(`上级地址`),
          scopedSlots: { customRender: 'SuperiorAddress' },
          dataIndex: 'parent_name',
          key: 'parentName',
          width: 100,
        },
        {
          title: this.$t(`地址别名`),
          scopedSlots: { customRender: 'MappingRelationships' },
          key: 'MappingRelationships',
          width: 180,
        },
        {
          title: this.$t(`创建/更新时间`),
          dataIndex: 'updated_at',
          key: 'updatedAt',
          width: 150,
        },
        {
          title: this.$t(`操作`),
          scopedSlots: { customRender: 'Action' },
          key: 'Action',
          width: 100,
        },
      ]
    },
  },
  components: {
    AddressBankModal,
    pagination,
    SelectRegion,
  },
}
</script>

<style lang="less" scoped>
.content-wrapper {
  // margin: 10px;
  padding: 10px;
}

.side {
  max-height: 1650px;
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ant-spin-nested-loading {
  min-height: 200px;
}
</style>
